import React, { useState, useEffect } from 'react';
import { Ahu3D } from '@musashi/ahu3d'; // Import Ahu3D module
import './Ahu3dModule.css'; // Import the CSS file

const Ahu3dModule = () => {
  const [ahu3d, setAhu3d] = useState(null); // State to store the Ahu3D instance
  const [mockXetoArray, setMockXetoArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Initialize the Ahu3D instance only once when the component mounts
    const instance = new Ahu3D({
      scene: {
        renderer: {
          size: {
            width: 850,
            height: 480
          }
        }
      }
    });

    instance.attachScene("#sceneContainer"); 

    const assetConfigs = {
      assetsPath: "https://ahu3d-assets.s3.amazonaws.com/assets/",
      componentList: [
        "LinearDuctSliced", "TJointSliced", "LJointSliced", "CrossJointSliced", 
        "InsertEndSliced", "CapEndSliced", "AirFilter", "AirFlowSensor", 
        "CoolingCoil", "Damper", "Fan", "GenericSensor", "HeatingCoil", 
        "TemperatureSensor"
      ]
    };

    instance.loadLibrary(assetConfigs);
    setAhu3d(instance); // Store the instance in state

    // Fetch mock Xeto data
    const fetchData = async () => {
      const files = [
        "/mock-data/xeto/instances-xeto-Cross1-3.0.json",
        "/mock-data/xeto/instances-xeto-sample-1.json",
        "/mock-data/xeto/instances-xeto-sample-2.json",
        "/mock-data/xeto/instances-xeto-sample-3.json",
        "/mock-data/xeto/instances-xeto-sample-4.json",
      ];

      try {
        const data = await Promise.all(
          files.map((file) =>
            fetch(file)
              .then((response) => response.json())
          )
        );
        setMockXetoArray(data);
      } 
      catch (err) {
        setError("Failed to load mock Xeto data.");
        console.error("Error loading mock data:", err);
      } 
      finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array to ensure this runs only once

  const loadMockXeto = async (index = 0) => {
    if (!ahu3d || mockXetoArray.length === 0) return;

    const mockXeto = mockXetoArray[index];
    try {
      await ahu3d.loadXeto(mockXeto);
    } catch (err) {
      console.error("Error loading Xeto:", err);
    }
  };

  return (
    <div id="pageWrapper" className="page-wrapper">
      <div id="titleContainer" className="title-container">
        <h1>Ahu3D Module</h1>
      </div>

      <div id="descriptionContainer" className="description-container">
        <p>This app showcases a React deployment of the Ahu3d app.</p>
      </div>

      <div id="sceneContainer" className="scene-container"></div>

      <div id="buttonContainer" className="button-container">
        <span style={{ marginRight: '6px' }}>Load Xeto Samples:</span>
        {loading && <p>Loading Xeto samples...</p>}
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {!loading && !error && [...Array(5)].map((_, idx) => (
          <button key={idx} onClick={() => loadMockXeto(idx)}> {idx + 1} </button>
        ))}
      </div>

      <div style={{ position:'absolute', bottom:'0px' }}>
        <p>PROPERTY OF COGNITIVE DYNAMICS LTD. - ALL RIGHTS RESERVED - 2024.</p>
      </div>

    </div>
  );
};

export default Ahu3dModule;
