import React from 'react';
import ReactDOM from 'react-dom/client'; // Use this instead of react-dom
import Ahu3dModule from './Ahu3dModule'; // Adjust the path based on your structure

// Get the root element from the DOM
const rootElement = document.getElementById('root');

// Create a root for React 18
const root = ReactDOM.createRoot(rootElement);

// Render your app component
root.render(<Ahu3dModule />);
